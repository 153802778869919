<template>
  <CreateUpdateTemplate
    :routePreventDialog="routePreventDialog"
    :hasChanges="hasChanges"
    :customClass="'job-ctx-create create-form'"
    v-if="getPermission('job:create') || getPermission('job:update')"
  >
    <template v-slot:header-title>
      <div class="form-action" v-if="!loaderLoding">
        <div class="form-title d-flex">
          Visit For
          <div
            v-if="vCustomerId"
            class="ml-3 form-title-link cursor-pointer text-h5"
            v-on:click="openDialog('customer')"
          >
            {{ vCustomer.display_name }}
          </div>
          <template v-else>
            <div
              class="ml-3 form-title-link cursor-pointer text-h5"
              v-on:click="openDialog('customer')"
            >
              Select a Customer
              <v-icon large color="cyan">mdi-account-check-outline</v-icon>
            </div>
            <div class="mx-3">OR</div>
            <div
              class="form-title-link cursor-pointer cyan--text text--darken-4 text-h5"
              v-on:click="createCustomerDialog()"
            >
              Create a new Customer
              <v-icon large color="cyan darken-4"
                >mdi-plus-circle-outline</v-icon
              >
            </div>
          </template>
        </div>
        <v-spacer></v-spacer>
        <v-chip
          label
          color="chip-custom-blue"
          outlined
          class="text-white p-3 mr-4"
          style="font-size: 21px !important; font-weight: 700"
        >
          <template>
            {{ jobEditData?.visit?.barcode ?? jobOption.visit_barcode }}
          </template>
        </v-chip>
        <div>
          <v-chip
            v-if="jobOption?.priventive?.barcode"
            label
            color="chip-custom-blue"
            outlined
            class="text-white p-3 mr-4"
            style="font-size: 21px !important; font-weight: 700"
          >
            <template>
              {{ jobOption.priventive.barcode }}
            </template>
          </v-chip>
        </div>
      </div>
    </template>
    <template v-slot:header-action>
      <div>
        <v-btn
          :disabled="jobLoading"
          :loading="jobLoading"
          v-on:click="goBack()"
          class="custom-bold-button custom-grey-border"
          depressed
          >Cancel</v-btn
        >
        <v-btn
          class="custom-bold-button ml-4"
          depressed
          :disabled="jobLoading"
          key=""
          :loading="jobLoading"
          v-on:click="updateOrCreate()"
          color="cyan white--text"
          >Save</v-btn
        >
      </div>
    </template>
    <template v-slot:body>
      <template v-if="loaderLoding">
        <v-container>
          <v-row>
            <v-col md="2"></v-col>
            <v-col md="8">
              <p class="font-size-22 font-weight-600 my-4 text-center">
                Please wait...
              </p>
              <v-progress-linear
                color="cyan"
                indeterminate
                rounded
                height="6"
              ></v-progress-linear>
              <br />
            </v-col>
            <v-col md="2"></v-col>
          </v-row>
        </v-container>
      </template>
      <div class="px-2" v-else>
        <perfect-scrollbar
          :options="{ suppressScrollX: true }"
          class="scroll"
          style="max-height: calc(100vh - 150px); position: relative"
        >
          <v-form
            ref="createTicketForm"
            v-model="formValid"
            lazy-validation
            v-on:submit.stop.prevent="updateOrCreate()"
          >
            <v-row>
              <v-col md="7">
                <!-- <div>
                <label class="required" :for="`ctx-job-title-${uniqueId}`"
                  >Title</label
                >
                <label class="required" :for="`ctx-job-title-${uniqueId}`"
                  >Title</label
                >
              </div> -->
                <div class="d-flex mb-2">
                  <div class="min-width-per-49 mr-2">
                    <label class="required" :for="`ctx-job-title-${uniqueId}`"
                      >Title</label
                    >

                    <TextField
                      id="job-title"
                      dense
                      filled
                      placeholder="Title"
                      solo
                      flat
                      counter="50"
                      v-model="jobCreate.title"
                      color="cyan"
                      :maxlength="250"
                      :rules="[
                        validateRules.required(jobCreate.title, 'Title'),
                      ]"
                    />
                  </div>
                  <div class="min-width-per-49 v_auto">
                    <label class="required" for="Job-Type">Job Type</label>
                    <v-autocomplete
                      hide-details
                      v-model="jobCreate.job_type"
                      :items="jobOption.ticket_types"
                      dense
                      flat
                      filled
                      placeholder="Job Types"
                      item-color="cyan"
                      color="cyan"
                      solo
                      item-value="value"
                      item-text="value"
                      @change="update_description"
                      :rules="[
                        validateRules.required(jobCreate.job_type, 'Job Type'),
                      ]"
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-title
                            v-html="'No Job Type(s) Found.'"
                          ></v-list-item-title>
                        </v-list-item>
                      </template>
                      <template v-slot:selection="{ item }">
                        <v-list class="d-flex align-center me-2 py-0">
                          <div>
                            <v-chip
                              style="
                                height: 16px;
                                width: 16px;
                                padding: 0;
                                border-radius: 50%;
                              "
                              :color="item.color"
                            >
                            </v-chip>
                          </div>
                          <v-list-item-title class="text-capitalize">
                            <template v-if="item.value == 'all'"
                              >{{ item.text }}
                            </template>
                            <template v-else>{{ item.text }} </template>
                          </v-list-item-title>
                        </v-list>
                      </template>
                      <template v-slot:item="{ item }">
                        <v-list-item-action class="mr-0">
                          <v-chip
                            style="height: 16px; width: 16px; padding: 0"
                            :color="item.color"
                          >
                          </v-chip>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title
                            class="text-capitalize font-weight-500 font-size-16"
                            >{{ item.text }}</v-list-item-title
                          >
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>
                  </div>
                </div>
                <TinyMCE v-model="jobCreate.description" />

                <TextAreaField
                  v-if="false"
                  auto-grow
                  dense
                  filled
                  color="cyan"
                  v-model="jobCreate.description"
                  placeholder="Description"
                  solo
                  flat
                  row-height="25"
                  counter="250"
                />

                <v-row>
                  <v-col md="6">
                    <v-layout class="align-center">
                      <v-flex md3
                        ><label class="my-0">Billing Location</label></v-flex
                      >
                      <v-flex md9 class="position-relative">
                        <v-icon
                          v-on:click="openDialog('billing')"
                          small
                          class="py-0 my-0"
                          color="cyan"
                          style="position: absolute; top: -8px"
                          >mdi-pencil</v-icon
                        >
                      </v-flex>
                    </v-layout>
                    <template v-if="vBilling?.id">
                      <span v-if="vBilling && vBilling.property_address">
                        <!--  <v-icon small>mdi-map-marker</v-icon> --></span
                      >
                      <template>
                        <div
                          class="custom-flex-d"
                          v-if="vBilling.property_name"
                        >
                          <b>
                            <v-icon
                              style="
                                font-size: 18px;
                                margin-top: -3px !important;
                              "
                              class="cmt-2"
                            >
                              mdi-home-map-marker
                            </v-icon>
                          </b>
                          <span>{{ vBilling.property_name }}<br /></span>
                        </div>
                        <div v-else>
                          <em class="text-muted">
                            <v-icon
                              style="
                                font-size: 18px;
                                margin-top: -3px !important;
                              "
                              class="cmt-2"
                            >
                              mdi-home-map-marker
                            </v-icon>
                            no Site Name</em
                          >
                        </div>
                      </template>

                      <template>
                        <div class="custom-flex-d">
                          <b>
                            <v-icon
                              style="
                                font-size: 18px;
                                margin-top: -3px !important;
                              "
                              class="cmt-2"
                            >
                              mdi-map-marker
                            </v-icon>
                          </b>
                          <span>
                            <template v-if="vBilling.unit_no"
                              >{{ vBilling.unit_no }},</template
                            >
                            <template v-if="vBilling.street_1"
                              >{{ vBilling.street_1 }},</template
                            >
                            {{ vBilling.street_2 }}
                            <br />
                            {{ vBilling.country }}
                            <template
                              v-if="
                                vBilling.zip_code &&
                                vBilling.zip_code != '000000'
                              "
                            >
                              {{ vBilling.zip_code }}
                            </template>
                          </span>
                        </div>
                      </template>
                    </template>
                    <template v-else-if="vCustomer?.id">
                      <p class="mb-0 red--text">
                        Please select billing location
                      </p>
                    </template>
                    <template v-else>
                      <p class="mb-0 red--text">Please select customer</p>
                    </template>
                    <v-layout class="mt-3 align-center">
                      <v-flex md3
                        ><label class="my-0">Contact Details</label></v-flex
                      >
                      <v-flex md9 class="position-relative">
                        <v-icon
                          small
                          v-on:click="openDialog('billing-contact')"
                          class="py-0 my-0"
                          color="cyan"
                          style="position: absolute; top: -8px"
                          >mdi-pencil</v-icon
                        >
                      </v-flex>
                    </v-layout>
                    <template v-if="vBillingContact?.id">
                      <p class="mb-0">{{ vBillingContact.display_name }}</p>
                      <p class="mb-0">{{ vBillingContact.primary_phone }}</p>
                      <p class="mb-0">{{ vBillingContact.primary_email }}</p>
                    </template>
                    <template v-else-if="vCustomer?.id">
                      <p class="mb-0 red--text">Please select contact person</p>
                    </template>
                    <template v-else>
                      <p class="mb-0 red--text">Please select customer</p>
                    </template>
                    <v-layout class="mt-3">
                      <v-flex md4
                        ><label class="my-0">Email Notification </label></v-flex
                      >
                      <v-flex md8>
                        <v-switch
                          v-model="jobCreate.billing_contact"
                          class="m-0 p-0"
                          color="cyan"
                          dense
                          inset
                        />
                      </v-flex>
                    </v-layout>
                  </v-col>
                  <v-col md="6">
                    <v-layout class="align-center">
                      <v-flex md3
                        ><label class="my-0">Site Location</label></v-flex
                      >
                      <v-flex md9 class="position-relative">
                        <v-icon
                          small
                          v-on:click="openDialog('property')"
                          class="py-0 my-0"
                          color="cyan"
                          style="position: absolute; top: -8px"
                          >mdi-pencil</v-icon
                        >
                      </v-flex>
                    </v-layout>
                    <template v-if="vProperty?.id">
                      <span v-if="vProperty && vProperty.property_address">
                        <!--   <v-icon small>mdi-map-marker</v-icon>--></span
                      >
                      <p
                        class="mr-2"
                        v-if="vProperty && vProperty.property_address"
                      >
                        <template>
                          <div
                            class="custom-flex-d"
                            v-if="vProperty?.property_name"
                          >
                            <b>
                              <v-icon
                                style="
                                  font-size: 18px;
                                  margin-top: -3px !important;
                                "
                                class="cmt-2"
                              >
                                mdi-home-map-marker
                              </v-icon>
                            </b>
                            <span>{{ vProperty?.property_name }}<br /></span>
                          </div>
                          <div v-else>
                            <em class="text-muted">
                              <v-icon
                                style="
                                  font-size: 18px;
                                  margin-top: -3px !important;
                                "
                                class="cmt-2"
                              >
                                mdi-home-map-marker
                              </v-icon>
                              no Site Name</em
                            >
                          </div>
                        </template>

                        <template>
                          <div class="custom-flex-d">
                            <b>
                              <v-icon
                                style="font-size: 18px; margin-top: -3px"
                                class="cmt-2"
                              >
                                mdi-map-marker
                              </v-icon>
                            </b>
                            <span>
                              <template v-if="vProperty?.unit_no"
                                >{{ vProperty?.unit_no }},</template
                              >
                              <template v-if="vProperty?.street_1"
                                >{{ vProperty?.street_1 }},</template
                              >
                              {{ vProperty?.street_2 }}
                              <br />
                              {{ vProperty?.country }}
                              <template
                                v-if="
                                  vProperty?.zip_code &&
                                  vProperty?.zip_code != '000000'
                                "
                              >
                                {{ vProperty?.zip_code }}
                              </template>
                            </span>
                          </div>
                        </template>
                      </p>
                    </template>

                    <template v-else-if="vCustomer?.id">
                      <p class="mb-0 red--text">Please select site location</p>
                    </template>
                    <template v-else>
                      <p class="mb-0 red--text">Please select customer</p>
                    </template>

                    <v-layout class="mt-3 align-center">
                      <v-flex md3
                        ><label class="my-0">Contact Details</label></v-flex
                      >
                      <v-flex md9 class="position-relative">
                        <v-icon
                          small
                          v-on:click="openDialog('property-contact')"
                          class="py-0 my-0"
                          color="cyan"
                          style="position: absolute; top: -8px"
                          >mdi-pencil</v-icon
                        >
                      </v-flex>
                    </v-layout>

                    <template v-if="vPropertyContact?.id">
                      <p class="mb-0">{{ vPropertyContact.display_name }}</p>
                      <p class="mb-0">{{ vPropertyContact.primary_phone }}</p>
                      <p class="mb-0">{{ vPropertyContact.primary_email }}</p>
                    </template>
                    <template v-else-if="vCustomer?.id">
                      <p class="mb-0 red--text">Please select contact person</p>
                    </template>
                    <template v-else>
                      <p class="mb-0 red--text">Please select customer</p>
                    </template>

                    <v-layout class="mt-3">
                      <v-flex md4
                        ><label class="my-0">Email Notification </label></v-flex
                      >
                      <v-flex md8>
                        <v-switch
                          v-model="jobCreate.property_contact"
                          class="m-0 p-0"
                          color="cyan"
                          dense
                          inset
                        />
                      </v-flex>
                    </v-layout>
                  </v-col>
                </v-row>
                <div v-if="false">
                  <label>Contract Number </label>
                  <v-autocomplete
                    id="contract"
                    dense
                    v-model="jobCreate.contract"
                    :items="vOption.contract_list"
                    item-text="barcode"
                    v-on:click:clear="contractDetail = {}"
                    clearable
                    item-color="cyan"
                    v-on:change="getContractDetail($event)"
                    item-value="id"
                    filled
                    hide-details
                    placeholder="Contract"
                    solo
                    flat
                    color="cyan"
                  >
                    <template v-slot:item="data">
                      <v-list-item-content>
                        <v-list-item-title class="px-0">{{
                          data.item.job_title
                        }}</v-list-item-title>
                        <v-list-item-subtitle>{{
                          data.item.barcode
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <div>
                          Start Date :
                          {{ formatDate(data.item.contract_start) }}
                        </div>
                        <div>
                          End Date : {{ formatDate(data.item.contract_end) }}
                        </div>
                      </v-list-item-action>
                    </template>
                  </v-autocomplete>
                  <v-list
                    v-if="
                      jobCreate.contract &&
                      contractDetail &&
                      contractDetail.is_contract
                    "
                  >
                    <v-list-item class="grey lighten-4 ml-1">
                      <v-list-item-content>
                        <v-list-item-title class="px-0">
                          <h5>
                            {{ contractDetail.job_title }}
                          </h5>
                        </v-list-item-title>
                        <v-list-item-subtitle>{{
                          contractDetail.barcode
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <div class="d-flex">
                          <div class="mx-5">
                            <v-chip class="mr-2" color="blue" outlined>
                              <span class="font-size-15">{{
                                contractDetail.total_service
                              }}</span>
                            </v-chip>
                            <b>Total Visit </b>
                          </div>
                          <div class="mx-5">
                            <v-chip class="mr-2" color="red" outlined>
                              <span class="font-size-15">{{
                                contractDetail.visit_count
                              }}</span>
                            </v-chip>
                            <b>Consumed </b>
                          </div>
                          <div class="mx-5">
                            <v-chip class="mr-2" color="orange" outlined>
                              <span class="font-size-15">{{
                                getBalance(contractDetail)
                              }}</span>
                            </v-chip>
                            <b>Balance</b>
                          </div>
                        </div>
                      </v-list-item-action>
                      <v-list-item-action>
                        <div>
                          <b>Start Date </b> :
                          {{ formatDate(contractDetail.contract_start) }}
                        </div>
                        <div>
                          <b>End Date </b>:
                          {{ formatDate(contractDetail.contract_end) }}
                        </div>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </div>
              </v-col>
              <v-col md="5">
                <table width="100%" v-if="false">
                  <tr>
                    <td width="200px">
                      <label :for="`ctx-job-visit-number-${uniqueId}`"
                        >Visit Number</label
                      >
                    </td>
                    <td>
                      <v-text-field
                        :id="`ctx-job-visit-number-${uniqueId}`"
                        dense
                        filled
                        class="font-weight-700"
                        :disabled="jobLoading"
                        readonly
                        :value="
                          jobEditData?.visit?.barcode ?? jobOption.visit_barcode
                        "
                        placeholder="Visit Number"
                        solo
                        flat
                        color="cyan"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td width="200px">
                      <label :for="`ctx-job-priority-${uniqueId}`"
                        >Priority</label
                      >
                    </td>
                    <td>
                      <div class="d-flex ml-1">
                        <v-btn
                          depressed
                          tile
                          :disabled="jobLoading"
                          :outlined="!(jobCreate.priority == 1)"
                          v-on:click="jobCreate.priority = 1"
                          value="1"
                          color="red darken-3 white--text"
                          >High</v-btn
                        >
                        <v-btn
                          class="mx-2"
                          depressed
                          tile
                          :disabled="jobLoading"
                          :outlined="!(jobCreate.priority == 2)"
                          v-on:click="jobCreate.priority = 2"
                          value="2"
                          color="orange darken-4 white--text"
                          >Medium</v-btn
                        >
                        <v-btn
                          depressed
                          tile
                          :disabled="jobLoading"
                          :outlined="!(jobCreate.priority == 3)"
                          v-on:click="jobCreate.priority = 3"
                          value="3"
                          color="light-green darken-3 white--text"
                          >Low</v-btn
                        >
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td width="200px">
                      <label :for="`ctx-job-amount-to-be-collected-${uniqueId}`"
                        >Amount to be collected</label
                      >
                    </td>
                    <td>
                      <v-text-field
                        :id="`ctx-job-amount-to-be-collected-${uniqueId}`"
                        dense
                        filled
                        :disabled="jobLoading"
                        placeholder="Amount to be collected"
                        solo
                        flat
                        type="number"
                        v-model="jobCreate.collected_amount"
                        v-on:keypress="
                          limitDecimal($event, jobCreate.collected_amount)
                        "
                        color="cyan"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <label
                        class="mb-0"
                        :for="`ctx-job-service-location-${uniqueId}`"
                      >
                        Service Location
                        <v-icon
                          :disabled="jobLoading"
                          v-on:click="propertyDialog = true"
                          class="mx-4"
                          small
                          color="cyan"
                        >
                          mdi-pencil
                        </v-icon>
                        <v-icon small color="cyan">mdi-history</v-icon>
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <p v-if="vPropertyId" class="mb-0">
                        {{ vProperty?.property_address }}
                      </p>
                      <p v-else-if="vCustomerId" class="mb-0 red--text">
                        Please select service location
                      </p>
                      <p v-else class="mb-0 red--text">
                        Please select customer
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <label
                        class="mb-0"
                        :for="`ctx-job-contact-details-${uniqueId}`"
                        >Contact Details</label
                      >
                    </td>
                  </tr>
                  <tr v-if="vPropertyContactId">
                    <td colspan="2">
                      <p class="mb-1">{{ vPropertyContact?.display_name }}</p>
                      <p class="mb-1">{{ vPropertyContact?.primary_phone }}</p>
                      <p class="mb-1">{{ vPropertyContact?.primary_email }}</p>
                    </td>
                  </tr>
                  <tr v-else-if="vCustomerId">
                    <td colspan="2">
                      <p class="mb-0 red--text">Please select contact person</p>
                    </td>
                  </tr>
                  <tr v-else>
                    <td colspan="2">
                      <p class="mb-0 red--text">Please select customer</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label :for="`ctx-job-email-notification-${uniqueId}`"
                        >Email Notification</label
                      >
                    </td>
                    <td>
                      <v-switch
                        v-model="jobCreate.email_notify"
                        :true-value="1"
                        :false-value="0"
                        :disabled="jobLoading"
                        class="m-0 p-0"
                        color="cyan"
                        dense
                        inset
                      />
                    </td>
                  </tr>
                </table>
                <table width="100%" style="table-layout: fixed">
                  <tr v-if="false">
                    <td width="20%">
                      <div class="d-flex ml-1 mt-4">
                        <v-btn
                          depressed
                          tile
                          :outlined="!(jobCreate.option == 'internal')"
                          v-on:click="jobCreate.option = 'internal'"
                          value="internal"
                          color="red darken-3 white--text"
                          style="font-size: 15px; margin-right: 20px"
                          >Internal</v-btn
                        >
                        <v-btn
                          style="font-size: 15px; margin-right: 20px"
                          class="mx-2"
                          depressed
                          tile
                          :outlined="!(jobCreate.option == 'external')"
                          v-on:click="jobCreate.option = 'external'"
                          value="external"
                          color="green darken-4 white--text"
                          >External</v-btn
                        >
                      </div>
                    </td>
                    <td></td>
                  </tr>

                  <tr v-if="false">
                    <template>
                      <td width="20%">
                        <label for="job-id">Visit #</label>
                      </td>
                      <td>
                        <v-text-field
                          id="job-id"
                          dense
                          filled
                          class="font-weight-700"
                          readonly
                          hide-details
                          placeholder="Visit # / Job #"
                          solo
                          flat
                          :value="
                            jobEditData?.visit?.barcode ??
                            jobOption.visit_barcode
                          "
                          color="cyan"
                        />
                      </td>
                    </template>
                  </tr>
                  <tr v-if="false">
                    <td>
                      <label for="job-type" class="required">Job Type</label>
                    </td>
                    <td>
                      <v-autocomplete
                        hide-details
                        v-model="jobCreate.job_type"
                        :items="jobOption.ticket_types"
                        dense
                        flat
                        filled
                        placeholder="Job Types"
                        item-color="cyan"
                        color="cyan"
                        solo
                        item-value="value"
                        item-text="value"
                        @change="update_description"
                        :rules="[
                          validateRules.required(
                            jobCreate.job_type,
                            'Job Type'
                          ),
                        ]"
                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-title
                              v-html="'No Job Type(s) Found.'"
                            ></v-list-item-title>
                          </v-list-item>
                        </template>
                        <template v-slot:selection="{ item }">
                          <v-list-item-action class="ma-0">
                            <v-chip
                              style="height: 16px; width: 16px; padding: 0"
                              :color="item.color"
                            >
                            </v-chip>
                          </v-list-item-action>
                          <v-list-item-content class="py-0">
                            <v-list-item-title
                              class="text-capitalize font-weight-500 font-size-16"
                            >
                              <template v-if="item.value == 'all'"
                                >{{ item.text }}
                              </template>
                              <template v-else>{{ item.text }} </template>
                            </v-list-item-title>
                          </v-list-item-content>
                        </template>
                        <template v-slot:item="{ item }">
                          <v-list-item-action class="mr-0">
                            <v-chip
                              style="height: 16px; width: 16px; padding: 0"
                              :color="item.color"
                            >
                            </v-chip>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title
                              class="text-capitalize font-weight-500 font-size-16"
                              >{{ item.text }}</v-list-item-title
                            >
                          </v-list-item-content>
                        </template>
                      </v-autocomplete>
                    </td>
                  </tr>
                  <tr
                    v-if="
                      pmVisitScheduleData?.length > 0 &&
                      showModules.preventive_maintanance
                    "
                  >
                    <template>
                      <td width="20%">
                        <label for="job-id">Consume PM</label>
                      </td>
                      <td @click="show_pm = true">
                        <v-tooltip
                          v-for="(row, index) in allConsumePMData?.preventives"
                          :key="'ticket-type' + index"
                          top
                          content-class="custom-top-tooltip"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip
                              v-bind="attrs"
                              v-on="on"
                              class="mr-2 font-weight-600"
                              color="chip-custom-blue"
                              label
                              small
                              outlined
                            >
                              {{ row.barcode }}
                            </v-chip>
                          </template>
                          <span>Consume PM</span>
                        </v-tooltip>
                      </td>
                    </template>
                  </tr>
                  <tr>
                    <td width="20%">
                      <label for="priority">Priority</label>
                    </td>
                    <td>
                      <div class="d-flex ml-1">
                        <v-btn
                          depressed
                          tile
                          :disabled="jobLoading"
                          :outlined="!(jobCreate.priority == 1)"
                          v-on:click="jobCreate.priority = 1"
                          value="1"
                          color="red darken-3 white--text"
                          >High</v-btn
                        >
                        <v-btn
                          class="mx-2"
                          depressed
                          tile
                          :disabled="jobLoading"
                          :outlined="!(jobCreate.priority == 2)"
                          v-on:click="jobCreate.priority = 2"
                          value="2"
                          color="orange darken-4 white--text"
                          >Medium</v-btn
                        >
                        <v-btn
                          depressed
                          tile
                          :disabled="jobLoading"
                          :outlined="!(jobCreate.priority == 3)"
                          v-on:click="jobCreate.priority = 3"
                          value="3"
                          color="light-green darken-3 white--text"
                          >Low</v-btn
                        >
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label for="reference-no">Reference #</label>
                    </td>
                    <td>
                      <v-text-field
                        id="reference-no"
                        dense
                        filled
                        v-model="jobCreate.reference"
                        hide-details
                        placeholder="Reference #"
                        solo
                        flat
                        color="cyan"
                      />
                    </td>
                  </tr>

                  <tr v-if="false">
                    <td>
                      <label for="tag">Quotation Number</label>
                    </td>
                    <td>
                      <v-autocomplete
                        id="job-type"
                        dense
                        v-model="jobCreate.quotation"
                        :items="jobOption.quotations"
                        item-text="value"
                        item-color="cyan"
                        item-value="id"
                        filled
                        hide-details
                        placeholder="Quotation Number"
                        solo
                        flat
                        color="cyan"
                      >
                      </v-autocomplete>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label for="po-number">PO Number</label>
                    </td>
                    <td>
                      <v-text-field
                        id="po-number"
                        dense
                        v-model="jobCreate.po_number"
                        filled
                        hide-details
                        placeholder="PO Number"
                        solo
                        flat
                        color="cyan"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label for="payment-mode">Payment Mode</label>
                    </td>
                    <td>
                      <v-autocomplete
                        id="payment-mode"
                        dense
                        v-model="jobCreate.payment_mode"
                        :items="jobOption.payment_modes"
                        item-text="value"
                        item-color="cyan"
                        item-value="id"
                        filled
                        hide-details
                        placeholder="Payment Mode"
                        solo
                        flat
                        color="cyan"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>Tags </label>
                    </td>
                    <td>
                      <TagAutoComplete type="visit" v-model="jobCreate.tags" />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label for="payment-mode"
                        >Is service form mandatory</label
                      >
                    </td>
                    <td>
                      <span
                        class="font-weight-600 d-flex"
                        style="margin-top: -3px !important"
                      >
                        <v-checkbox
                          color="cyan"
                          v-model="jobCreate.is_service_form"
                        ></v-checkbox>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label for="payment-mode">Line Item Visible In PDF</label>
                    </td>
                    <td>
                      <span
                        class="font-weight-600 d-flex"
                        style="margin-top: -3px !important"
                      >
                        <v-checkbox
                          color="cyan"
                          v-model="jobCreate.is_item_pdf"
                        ></v-checkbox>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label for="payment-mode"
                        >Payment collection needed</label
                      >
                    </td>
                    <td>
                      <span
                        class="font-weight-600 d-flex"
                        style="margin-top: -3px !important"
                      >
                        <v-checkbox
                          color="cyan"
                          v-model="jobCreate.is_payment_needed"
                          v-on:change="
                            showCollectedAmount(jobCreate.is_payment_needed)
                          "
                        ></v-checkbox>
                      </span>
                    </td>
                  </tr>
                </table>
              </v-col>
              <v-col md="12" class="job-schedule pb-0">
                <v-tabs
                  v-if="!isEditPage && !pmVisitScheduleData?.length && false"
                  v-model="jobCreate.schedule_tab"
                  background-color="transparent"
                  centered
                  :disabled="jobLoading"
                  color="cyan"
                  icons-and-text
                  class="mb-3"
                  v-on:change="updateScheduleType($event)"
                >
                  <v-tab
                    :disabled="jobLoading"
                    href="#one-off"
                    style="min-width: 390px"
                  >
                    <div class="custom-tab-title d-flex">
                      <div class="custom-tab-icon pr-2">
                        <v-icon left large color="gray">mdi-calendar</v-icon>
                      </div>
                      <div class="text-left">
                        <h3 class="text-uppercase font-weight-700">
                          Adhoc job
                        </h3>
                        <p class="m-0 text-capitalize">
                          A one time job with one or more visits
                        </p>
                      </div>
                    </div>
                  </v-tab>
                  <v-tab
                    :disabled="jobLoading"
                    href="#recurring"
                    style="min-width: 390px"
                  >
                    <div class="custom-tab-title d-flex">
                      <div class="custom-tab-icon pr-2">
                        <v-icon left large color="gray"
                          >mdi-calendar-multiple</v-icon
                        >
                      </div>
                      <div class="text-left">
                        <h3 class="text-uppercase font-weight-700">
                          Recurring Job
                        </h3>
                        <p class="m-0 text-capitalize">
                          A contract job with repeating visits
                        </p>
                      </div>
                    </div>
                  </v-tab>
                </v-tabs>
                <v-tabs-items
                  v-if="(isEditPage && isEditLoaded) || (!isEditPage && false)"
                  v-model="jobCreate.schedule_tab"
                  class="remvoe-overflow"
                >
                  <v-tab-item value="one-off"> </v-tab-item>
                  <v-tab-item value="recurring"> </v-tab-item>
                </v-tabs-items>
              </v-col>
              <v-col md="12">
                <v-expansion-panels :disabled="jobLoading" flat tile multiple>
                  <v-expansion-panel
                    v-if="jobCreate.schedule_tab == 'one-off'"
                    class="mb-0 custom-border"
                  >
                    <v-expansion-panel-header color="grey lighten-4">
                      <div class="my-3">
                        <h3 class="text-capitalize color-custom-blue">
                          Adhoc job
                        </h3>
                        <p class="m-0 text-capitalize">
                          A one time job with one or more visits
                        </p>
                      </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <div>
                        <OneOffSchedule
                          :key="`job-one-off-schedule-${scheduleKey}`"
                        />
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel
                    v-if="jobCreate.schedule_tab == 'recurring'"
                    class="mb-0 custom-border"
                  >
                    <v-expansion-panel-header color="grey lighten-4">
                      <div class="my-3">
                        <h3 class="text-capitalize color-custom-blue">
                          Recurring Job
                        </h3>
                        <p class="m-0 text-capitalize">
                          A contract job with repeating visits
                        </p>
                      </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <div>
                        <RecurringSchedule
                          :key="`job-recurring-schedule-${scheduleKey}`"
                        />
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
              <v-col cols="12" ref="lineItem">
                <div class="customer-border">
                  <div
                    class="py-3 grey lighten-4"
                    @click="show_line_items = !show_line_items"
                  >
                    <div class="d-flex align-start">
                      <div class="px-3">
                        <v-icon>{{
                          !show_line_items
                            ? "mdi-chevron-down"
                            : "mdi-chevron-up"
                        }}</v-icon>
                      </div>
                      <div>
                        <h3 class="text-capitalize color-custom-blue">
                          Line Items
                        </h3>
                      </div>
                    </div>
                  </div>
                  <v-expand-transition>
                    <div class="px-3 py-2" v-show="show_line_items">
                      <LineItem
                        is-job
                        :is-collected="collectedFlag"
                        :item-set="setIteam"
                        :related-type="related_type"
                        :related-detail="updatetedTicket"
                        :key="`job-line-item-${uniqueId}`"
                        v-on:remove:payload="removePmItamRow"
                      />
                    </div>
                  </v-expand-transition>
                </div>
              </v-col>
              <v-col class="py-0" cols="12" ref="invoicing">
                <div
                  class="customer-border"
                  v-if="
                    jobCreate.schedule_tab == 'one-off' &&
                    is_visit_invoice_allow
                  "
                >
                  <div
                    class="py-3 grey lighten-4"
                    @click="show_invoicing = !show_invoicing"
                  >
                    <div class="d-flex align-start">
                      <div class="px-3">
                        <v-icon>{{
                          !show_invoicing
                            ? "mdi-chevron-down"
                            : "mdi-chevron-up"
                        }}</v-icon>
                      </div>
                      <div>
                        <h3 class="text-capitalize color-custom-blue">
                          Invoicing
                        </h3>
                      </div>
                    </div>
                  </div>
                  <v-expand-transition>
                    <div class="px-3 py-2" v-if="show_invoicing">
                      <InvoiceSchedule></InvoiceSchedule>
                    </div>
                  </v-expand-transition>
                </div>
                <div
                  class="customer-border"
                  v-if="
                    jobCreate.schedule_tab == 'recurring' &&
                    is_visit_invoice_allow &&
                    is_visit_recur_invoice_allow
                  "
                >
                  <div class="py-3 grey lighten-4">
                    <div class="d-flex align-start">
                      <div
                        class="d-flex"
                        @click="show_invoicing = !show_invoicing"
                      >
                        <div class="px-3">
                          <v-icon>{{
                            !show_invoicing
                              ? "mdi-chevron-down"
                              : "mdi-chevron-up"
                          }}</v-icon>
                        </div>
                        <div>
                          <h3 class="text-capitalize color-custom-blue">
                            Recurring Invoicing
                          </h3>
                        </div>
                      </div>
                      <v-spacer></v-spacer>
                      <v-btn
                        v-if="
                          recurringinvoice?.invoice_type != 'no_need_auto' &&
                          recurringinvoice?.invoice_type !=
                            'remind_me_job_closed'
                        "
                        v-on:click="scheduleDialog = true"
                        depressed
                        tile
                        :disabled="jobLoading"
                        value="3"
                        color="cyan white--text me-2"
                        >Show Schedule</v-btn
                      >
                    </div>
                  </div>
                  <v-expand-transition>
                    <div class="px-3 py-2" v-if="show_invoicing">
                      <InvoiceScheduleRecurring></InvoiceScheduleRecurring>
                    </div>
                  </v-expand-transition>
                </div>
              </v-col>
              <v-col cols="12">
                <div class="customer-border">
                  <div
                    class="py-3 grey lighten-4"
                    @click="
                      show_terms_and_conditions = !show_terms_and_conditions
                    "
                  >
                    <div class="d-flex align-start">
                      <div class="px-3">
                        <v-icon>{{
                          !show_terms_and_conditions
                            ? "mdi-chevron-down"
                            : "mdi-chevron-up"
                        }}</v-icon>
                      </div>
                      <div>
                        <h3 class="text-capitalize color-custom-blue">
                          Terms &amp; Conditions
                        </h3>
                      </div>
                    </div>
                  </div>
                  <v-expand-transition>
                    <div class="px-3 py-3" v-if="show_terms_and_conditions">
                      <v-autocomplete
                        id="payment-mode"
                        dense
                        v-model="jobCreate.term_condition_id"
                        :items="jobOption.term_condition_list"
                        item-text="label"
                        item-color="cyan"
                        item-value="id"
                        class="pl-0 mb-3"
                        filled
                        hide-details
                        placeholder="Select Terms and conditions"
                        solo
                        flat
                        color="cyan"
                        v-on:change="update_term_condition()"
                      />
                      <TinyMCE v-model="jobCreate.term_conditions" />
                    </div>
                  </v-expand-transition>
                </div>
              </v-col>
              <v-col cols="12" class="pb-5">
                <div class="customer-border">
                  <div
                    class="py-3 grey lighten-4"
                    @click="
                      show_notes_and_attachment = !show_notes_and_attachment
                    "
                  >
                    <div class="d-flex align-start">
                      <div class="px-3">
                        <v-icon>{{
                          !show_notes_and_attachment
                            ? "mdi-chevron-down"
                            : "mdi-chevron-up"
                        }}</v-icon>
                      </div>
                      <div>
                        <h3 class="text-capitalize color-custom-blue">
                          Notes &amp; Attachments
                        </h3>
                      </div>
                    </div>
                  </div>
                  <v-expand-transition>
                    <div class="px-3 py-2" v-if="show_notes_and_attachment">
                      <v-row>
                        <v-col cols="6">
                          <p class="font-weight-600 mb-2">
                            Client Note
                            <span class="text--red"> </span>
                          </p>
                          <TinyMCE v-model="jobCreate.client_remark" />
                        </v-col>
                        <v-col cols="6">
                          <div class="d-flex">
                            <span class="font-weight-600">
                              Admin Note (internal use only)
                            </span>
                            <v-spacer></v-spacer>
                            <span
                              class="font-weight-600 d-flex"
                              style="margin-top: -3px !important"
                            >
                              <v-checkbox
                                color="cyan"
                                v-model="jobCreate.is_remark_show"
                              ></v-checkbox>
                              <span class="mt-1">Show In Application</span>
                            </span>
                          </div>
                          <TinyMCE v-model="jobCreate.admin_remark" />
                        </v-col>
                        <v-col cols="6" class="pb-0">
                          <p class="font-weight-600 mb-1">
                            Attachments
                            <span class="text--red"></span>
                          </p>
                          <DocumnetList
                            v-model="jobCreate.vPdfAttachments"
                            :document-type="docType"
                          >
                          </DocumnetList>
                          <FileUpload
                            v-model="jobCreate.vPdfAttachments"
                            :maxLimit="5"
                            v-if="false"
                          >
                          </FileUpload>
                        </v-col>
                        <v-col cols="6" class="pb-0">
                          <p class="font-weight-600 mb-1">
                            Attachments (internal use only)
                          </p>
                          <DocumnetList
                            v-model="jobCreate.attachments"
                            :document-type="docType"
                          >
                          </DocumnetList>
                          <FileUpload
                            v-if="false"
                            v-model="jobCreate.attachments"
                            :maxLimit="5"
                          >
                          </FileUpload>
                        </v-col>
                      </v-row>
                    </div>
                  </v-expand-transition>
                </div>
              </v-col>
            </v-row>
          </v-form>
        </perfect-scrollbar>
        <!--  <CustomerSelect
          :key="`job-customer-select-${dailogKey}`"
          :dialog="customerDialog"
          v-on:close="customerDialog = false"
        /> -->
        <CustomerSelect
          :dialog="reCustomerDialog"
          @close="closeCustomerDialog"
        />
        <customer-create :key="`pm-customer-create-${dailogKey}`" />

        <PersonSelect
          :key="`job-person-select-${dailogKey}`"
          :dialog="contactDialog"
          v-on:close="contactDialog = false"
          :type="contactType"
        />
        <AddressSelect
          :dialog="propertyDialog"
          v-on:close="propertyDialog = false"
          :type="propertyType"
          :label="propertyLabel"
        />

        <!--  <AddressSelect
          :key="`job-address-select-${dailogKey}`"
          :dialog="propertyDialog"
          v-on:close="propertyDialog = false"
          type="property"
          label="Service Location"
          placeholder="Service Location"
        /> -->

        <template>
          <Dialog :common-dialog="confirmDialog">
            <template v-slot:title>Confirmation</template>
            <template v-slot:body>
              <v-container fluid class="py-0">
                <div
                  v-if="updateScheduleChanged"
                  class="p-4 mb-4 font-size-17 font-weight-400 warning-background"
                >
                  <p
                    class="mb-0 d-flex align-center font-weight-600 red--text text--darken-3"
                  >
                    <v-icon color="red darken-3" class="mr-2"
                      >mdi-alert-circle-outline</v-icon
                    >
                    Attention! Schedule has been Changed.
                  </p>
                </div>

                <div
                  v-if="updateTeamChanged && !updateScheduleChanged"
                  class="p-4 mb-4 font-size-17 font-weight-400 warning-background"
                >
                  <p
                    class="mb-0 d-flex align-center font-weight-600 red--text text--darken-3"
                  >
                    <v-icon color="red darken-3" class="mr-2"
                      >mdi-alert-circle-outline</v-icon
                    >
                    Attention! Team has been Changed.
                  </p>
                </div>

                <v-radio-group
                  v-model="updateTargetType"
                  row
                  v-if="updateTeamChanged && !updateScheduleChanged"
                >
                  <v-radio
                    color="cyan"
                    label="Update the current visit only?"
                    value="self"
                  ></v-radio>
                  <v-radio
                    color="cyan"
                    label="Update all upcoming visits?"
                    value="upcomming"
                  ></v-radio>
                </v-radio-group>

                <div
                  v-if="
                    updateTargetType == 'upcomming' && updateScheduleChanged
                  "
                  class="p-4 mt-4 font-size-16 font-weight-400 warn-warning-background"
                >
                  <p
                    class="mb-0 d-flex align-center font-weight-600 orange--text text--darken-4"
                  >
                    <v-icon color="orange darken-4" class="mr-2"
                      >mdi-alert-circle-outline</v-icon
                    >
                    Note: The visits scheduled ahead will be changed with a new
                    SCHEDULE and TEAM. All open Visits will be deleted and
                    create new
                    {{ scheduleOutput?.length }} visits with same schedule..
                  </p>
                </div>
              </v-container>
            </template>
            <template v-slot:action>
              <v-btn
                class="mx-2 custom-grey-border custom-bold-button white--text"
                color="cyan"
                :disabled="jobLoading"
                v-on:click="
                  scheduleChangeConfirmed = true;
                  updateOrCreate();
                "
              >
                Continue
              </v-btn>
              <v-btn
                :disabled="jobLoading"
                class="mx-2 custom-grey-border custom-bold-button"
                v-on:click="confirmDialog = false"
              >
                Close
              </v-btn>
            </template>
          </Dialog>
        </template>
        <Dialog :common-dialog="show_pm">
          <template v-slot:title>Consume PM</template>
          <template v-slot:body>
            <v-container fluid class="py-0">
              <v-row>
                <v-col md="12" class="pt-0 custom-listing-table">
                  <template
                    v-for="(row, EIndex) in allConsumePMData?.preventives"
                  >
                    <table width="100%" class="white-background" :key="EIndex">
                      <tbody class="custom-border-bottom">
                        <tr :class="{ 'orange lighten-5': EIndex % 2 }">
                          <td
                            class="simple-table-td"
                            v-on:click="viewPm(row.id)"
                          >
                            <div class="quotation-listing-customer">
                              <div
                                class="m-0 custom-nowrap-ellipsis d-flex mb-1"
                              >
                                <div style="width: 100px">PM #</div>
                                <span
                                  ><v-chip
                                    small
                                    outlined
                                    color="chip-custom-blue"
                                    label
                                    class="text-capitalize"
                                  >
                                    {{ row.barcode }}
                                  </v-chip></span
                                >
                              </div>
                              <div
                                class="m-0 custom-nowrap-ellipsis d-flex mb-1"
                              >
                                <div style="width: 100px">Title</div>
                                <span>{{ row.title }}</span>
                              </div>
                              <div
                                class="m-0 custom-nowrap-ellipsis d-flex mb-1"
                              >
                                <div style="width: 100px">Type</div>
                                <span>
                                  <v-chip
                                    small
                                    outlined
                                    color="blue"
                                    label
                                    class="text-capitalize"
                                  >
                                    {{
                                      row.btx_schedule_tab.replaceAll("_", " ")
                                    }}
                                  </v-chip></span
                                >
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </template>
                </v-col>
              </v-row>
            </v-container>
          </template>
          <template v-slot:action>
            <v-btn
              class="mx-2 custom-grey-border custom-bold-button"
              v-on:click="show_pm = false"
            >
              Close
            </v-btn>
          </template>
        </Dialog>
        <ShowScheduleRecurring
          :dialog="scheduleDialog"
          v-on:next="nextStepSchedule()"
          :key="`job-show-schedule-${uniqueId}`"
          v-on:close="scheduleDialog = false"
        />
      </div>
    </template>
  </CreateUpdateTemplate>
</template>

<style lang="scss" scoped></style>

<script>
import ShowScheduleRecurring from "@/view/components/ShowScheduleRecurring.vue";
import DocumnetList from "@/view/pages/DocumnetList";
import { mapGetters } from "vuex";
import { toSafeInteger } from "lodash";
import CreateUpdateTemplate from "@/view/pages/partials/Create-Update-Template.vue";
import { PreventDialogEventBus } from "@/core/lib/prevent.reload.lib";
import ValidationMixin from "@/core/plugins/validation-mixin";
import OneOffSchedule from "@/view/components/main-job/OneOffSchedule.vue";
import RecurringSchedule from "@/view/components/main-job/RecurringSchedule.vue";
import LineItem from "@/view/pages/partials/Line-Item-V2.vue";
import CustomerSelect from "@/view/components/CustomerSelect.vue";
import AddressSelect from "@/view/components/main-job/AddressSelect.vue";
import PersonSelect from "@/view/components/PersonSelect.vue";
import CustomerCreate from "@/view/components/CustomerCreateDialog.vue";
import TinyMCE from "@/view/pages/partials/TinyMCE.vue";
import Dialog from "@/view/pages/partials/Dialog";
import ObjectPath from "object-path";
import {
  SET_DIALOG_STATUS,
  SET_CREATE_DIALOG_STATUS,
} from "@/core/services/store/customer.module";
import {
  CLEAR_TICKET,
  GET_TICKET,
  GET_OPTION,
  SET_SCHEDULE_TYPE,
  CREATE_OR_UPDATE,
  /* SET_LOADING, */
  UPDATE_SCHEDULE_STATE,
  GET_RECURRING_DATE,
  UPDATE_DEFAULT_DATE,
} from "@/core/services/store/job.module";
import {
  CLEAR_INVOICE,
  UPDATE_INVOICE_SCHEDULE_RECURRING,
  UPDATE_INVOICE_SCHEDULE_ONE_OFF,
} from "@/core/services/store/invoice.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import ManageJob from "@/core/plugins/job.plugin";
import moment from "moment-timezone";
import { UPLOAD } from "@/core/services/store/request.module";
import TagAutoComplete from "@/view/pages/partials/TagAutoCompleteInput";
import CommonMixin from "@/core/plugins/common-mixin";

import {
  SET_EDIT_MODE,
  CLEAR_CUSTOMER,
} from "@/core/services/store/customer.module";
import {
  SET_CUSTOMER,
  RESET_STATE,
  SET_BILLING,
  SET_BILLING_CONTACT,
  SET_PROPERTY,
  SET_PROPERTY_CONTACT,
  RESET_CREATE_STATE,
  SET_VISIT_LINE_ITEM,
} from "@/core/services/store/visit.module";
import { QUERY } from "@/core/services/store/request.module";
import FileUpload from "@/view/components/app-component/FileInput.vue";
import InvoiceSchedule from "@/view/components/main-job/InvoiceSchedule.vue";
import InvoiceScheduleRecurring from "@/view/components/main-job/InvoiceScheduleRecurring.vue";
import TextAreaField from "@/view/components/app-component/TextAreaField.vue";

export default {
  mixins: [ValidationMixin, CommonMixin],
  name: "job-ctx-create",
  title: "Create Job",
  data() {
    return {
      docType: 302,
      scheduleDialog: false,
      confirmDialog: false,
      jobCreated: false,
      loaderLoding: false,
      show_pm: false,
      updateScheduleChanged: false,
      scheduleChangeConfirmed: false,
      updateTeamChanged: false,
      show_notes_and_attachment: false,
      updateTargetType: "upcomming",
      ticketId: 0,
      visitId: 0,
      related_type: 3,
      show_invoicing: false,
      show_line_items: false,
      show_recurring_job: false,
      show_attachments: false,
      show_terms_and_conditions: false,
      duplicateTicketId: 0,
      duplicateVisitId: 0,
      pmVisitScheduleData: [],
      allConsumePMData: [],
      /*    dbPriventiveId: 0, */
      dbEnquiryId: 0,
      dbQuotationId: 0,
      dbInvoiceId: 0,
      dbschedule_id: 0,
      uniqueId: Number(new Date()),
      scheduleKey: Number(new Date()),
      dailogKey: Number(new Date()),
      formValid: true,
      customerDialog: false,
      contactDialog: false,
      propertyDialog: false,
      contactType: null,
      propertyType: null,
      updatetedTicket: {},
      vPdfAttachments: [],
      pmPayloadVisit: [],
      attachments: [],
      isEditPage: false,
      propertyLabel: null,
      isPreventivePage: false,
      setIteam: false,
      updateTicketId: 0,
      updateVisitId: 0,
      isEditLoaded: false,
      collectedFlag: false,
      is_visit_recur_invoice_allow: true,
      is_visit_invoice_allow: true,
      jobCreate: {
        schedule_tab: "one-off",
        title: null,
        description: null,
        is_remark_show: false,
        schedule_later: false,
        is_service_form: true,
        is_item_pdf: true,
        is_payment_needed: false,
        priority: "3",
        term_condition_id: 0,
        reference: null,
        job_type: null,
        option: "internal",
        tags: null,
        po_number: null,
        quotation: 0,
        payment_mode: null,
        project: null,
        barcode: null,
        contract: null,
        invoice: null,
        term_conditions: null,
        client_remark: null,
        admin_remark: null,
        milestones: [],
        attachments: [],
        vPdfAttachments: [],
      },
    };
  },
  beforeCreate() {
    /*   KTCookie.setCookie("kt_aside_toggle_state", "on");
      localStorage.setItem("kt_aside_toggle_state", "on"); */
    this.$store.dispatch(CLEAR_CUSTOMER);
    this.$store.dispatch(RESET_CREATE_STATE);
    /*  this.$store.dispatch(CLEAR_LINE_ITEM); */
  },
  computed: {
    ...mapGetters([
      "defaultStartedAt",
      "defaultFinishedAt",
      "vDupDialog",
      "vDupVisit",
      "jobEditData",
      "jobOption",
      "vCalculations",
      "vSelectedLineItem",
      "scheduleOutput",
      "oneOffSchedule",
      "recurringSchedule",
      "assignedTeam",
      "assignedTeamObject",
      "reCustomerDialog",
      "jobLoading",
      "vCustomerId",
      "vPropertyId",
      "vPropertyContactId",
      "vBillingId",
      "vBillingContactId",
      "vCustomer",
      "vProperty",
      "vPropertyContact",
      "vBilling",
      "vBillingContact",
      "recurringScheduleTypeList",
      "oneoffinvoice",
      "recurringinvoice",
      "invoiceVisitScheduleOutput",
      "scheduleOutput",
    ]),
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 60);
    },
  },
  components: {
    /*  Barcode, */
    ShowScheduleRecurring,
    Dialog,
    TinyMCE,
    LineItem,
    /*   CustomStatus, */
    CustomerSelect,
    "customer-create": CustomerCreate,
    TagAutoComplete,
    RecurringSchedule,
    OneOffSchedule,
    AddressSelect,
    PersonSelect,
    FileUpload,
    InvoiceSchedule,
    InvoiceScheduleRecurring,
    DocumnetList,
    TextAreaField,
    CreateUpdateTemplate,
  },
  watch: {
    vCustomerId(param) {
      if (param) {
        this.getVisitOption();
      }
    },
  },
  methods: {
    nextStepSchedule() {
      this.scheduleDialog = false;
    },
    showCollectedAmount(value) {
      if (value) {
        this.collectedFlag = true;
      } else {
        this.collectedFlag = false;
      }
    },
    formatDateStartTime(row) {
      return moment(row).format("hh:mm A");
    },
    viewPm(id) {
      this.$router.push(
        this.getDefaultRoute("preventive-maintanance.detail", {
          params: { id: id },
        })
      );
    },
    removePmItamRow(data) {
      if (data && this.pmPayloadVisit && this.pmPayloadVisit.length > 0) {
        const foundIndex = this.pmPayloadVisit.findIndex(
          (item) => item.pm_id == data.preventive_id
        );
        let filterPm = this.pmPayloadVisit[foundIndex];
        if (filterPm && filterPm.length > 0) {
          const founditemIndex = filterPm.iteam.findIndex(
            (item) => item == data.id
          );
          filterPm.iteam.splice(founditemIndex, 1);
        }
      }
    },
    closeCustomerDialog() {
      this.$store.commit(SET_DIALOG_STATUS, false);
    },
    /*  openDialog(type) {
        this.$store.commit(SET_DIALOG_STATUS, false);
        if (type == "customer") {
          this.$store.commit(SET_DIALOG_STATUS, true);
        } else {
          if (!this.vCustomerId) {
            ErrorEventBus.$emit("update:error", "Please Select Customer.");
            return false;
          }
        }
      }, */
    createCustomerDialog() {
      this.dailogKey = Number(new Date());
      this.$nextTick(() => {
        this.$store.commit(SET_CREATE_DIALOG_STATUS, true);
      });
    },
    update_term_condition() {
      let output = this.lodash.find(this.jobOption.term_condition_list, {
        id: this.jobCreate.term_condition_id,
      });
      if (output) {
        this.jobCreate.term_conditions = output.description;
      }
    },
    update_description() {
      let output = this.lodash.find(this.jobOption.ticket_types, {
        value: this.jobCreate.job_type,
      });
      if (output) {
        this.jobCreate.description = output.description;
      }
    },
    openDialog(type) {
      this.$store.commit(SET_DIALOG_STATUS, false);
      this.propertyType = null;
      this.propertyLabel = null;
      this.propertyDialog = false;
      this.contactType = null;
      this.contactDialog = false;

      if (type == "customer") {
        this.$store.commit(SET_DIALOG_STATUS, true);
      } else {
        if (!this.vCustomerId) {
          this.scrollToTop();
          ErrorEventBus.$emit("update:error", "Please Select Customer.");
          return false;
        }
        if (type == "billing") {
          this.propertyType = "billing";
          this.propertyLabel = "Billing Location";
          this.propertyDialog = true;
        }
        if (type == "billing-contact") {
          this.contactType = "billing";
          this.contactDialog = true;
        }
        if (type == "property") {
          this.propertyType = "property";
          this.propertyLabel = "Site Location";
          this.propertyDialog = true;
        }
        if (type == "property-contact") {
          this.contactType = "property";
          this.contactDialog = true;
        }
      }
    },

    uploadFiles() {
      let _this = this;
      return new Promise((resolve, reject) => {
        let formData = new FormData();

        let status = false;

        for (let i = 0; i < _this.attachments.length; i++) {
          if (_this.attachments[i].accepted_file) {
            status = true;
            formData.append(
              `files[${i}]`,
              _this.attachments[i].accepted_file,
              _this.attachments[i].file_name
            );
          }
        }

        if (!status) {
          resolve([]);
        } else {
          _this.$store
            .dispatch(UPLOAD, { url: "file-manager/upload", data: formData })
            .then(({ data }) => {
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        }
      });
    },
    removeRow(target, index) {
      ObjectPath.del(this, `${target}.${index}`);
      this.$nextTick(() => {
        if (!this.attachments.length) {
          this.addAttachmentRow();
        }
      });
    },
    addAttachmentRow() {
      this.attachments.push({
        accepted_file: null,
        file_name: null,
        file_type: null,
      });
    },
    getFileExtension(file_type) {
      if (file_type) {
        return `.${file_type}`;
      }
      return null;
    },
    updateFileName(index) {
      const file = this.attachments[index].accepted_file;
      if (file) {
        this.attachments[index].file_name = file.name
          .split(".")
          .slice(0, -1)
          .join(".");
        this.attachments[index].file_type = file.name.split(".").pop();
        this.addAttachmentRow();
      }
    },
    async updateOrCreate() {
      try {
        if (!this.vCustomerId) {
          ErrorEventBus.$emit(
            "update:error",
            InitializeError("Please Select Customer.")
          );
          return false;
        }

        if (!this.vPropertyId) {
          ErrorEventBus.$emit(
            "update:error",
            InitializeError("Please Select Site Location.")
          );
          return false;
        }

        if (!this.vPropertyContactId) {
          ErrorEventBus.$emit(
            "update:error",
            InitializeError("Please Select Contact Person.")
          );
          return false;
        }
        if (this.jobCreate.schedule_tab == "recurring") {
          if (!this.recurringSchedule.message) {
            ErrorEventBus.$emit(
              "update:error",
              InitializeError(
                "Oops... Dates are not available, Please select valid schedule.."
              )
            );
            return false;
          }
        }
        const allHeaders = this.vSelectedLineItem.every(
          (obj) => obj.type === "header"
        );
        if (
          !this.vSelectedLineItem ||
          !this.vSelectedLineItem.length ||
          allHeaders
        ) {
          ErrorEventBus.$emit("update:error", "Select Line Item");
          return false;
        }
        const AllItems = this.vSelectedLineItem;
        if (AllItems?.length > 0) {
          for (var k = 0; k < AllItems.length; k++) {
            if (!AllItems[k].product) {
              ErrorEventBus.$emit(
                "update:error",
                AllItems[k].type + " title is required."
              );
              return false;
            }
          }
        }
        if (
          this.jobCreate.is_payment_needed &&
          this.vCalculations.collected_amount == 0
        ) {
          ErrorEventBus.$emit("update:error", "Please enter collected amount");
          return false;
        }
        const validateStatus = this.$refs.createTicketForm.validate();

        const formErrors = this.validateForm(this.$refs.createTicketForm);

        if (formErrors.length) {
          for (let i = 0; i < formErrors.length; i++) {
            ErrorEventBus.$emit("update:error", InitializeError(formErrors[i]));
          }
          return false;
        }

        if (!validateStatus) {
          return false;
        }

        if (
          !this.scheduleChangeConfirmed &&
          this.isEditPage &&
          this.jobCreate.schedule_tab == "recurring" &&
          !this.isPreventivePage
        ) {
          this.updateScheduleChanged = false;
          this.updateTeamChanged = false;
          this.confirmDialog = false;

          const jobInstance = new ManageJob();

          if (
            !jobInstance.scheduleCompare(
              this.jobEditData,
              this.recurringSchedule
            )
          ) {
            this.confirmDialog = true;
            this.updateScheduleChanged = true;
            if (!jobInstance.teamCompare(this.jobEditData, this.assignedTeam)) {
              this.updateTeamChanged = true;
            }
            return false;
          }

          if (!jobInstance.teamCompare(this.jobEditData, this.assignedTeam)) {
            this.confirmDialog = true;
            this.updateTeamChanged = true;
            return false;
          }
        }

        /*  const new_attachments = await this.uploadFiles(); */

        if (this.pmPayloadVisit && this.pmPayloadVisit.length > 0) {
          let newArray = [];
          if (Array.isArray(this.pmPayloadVisit)) {
            this.pmPayloadVisit.forEach((row, index) => {
              if (!row.iteam) {
                let item = [];
                if (Array.isArray(this.vSelectedLineItem)) {
                  this.vSelectedLineItem.forEach((crow) => {
                    if (
                      row.pm_id == crow.preventive_id &&
                      crow.is_consumed == 1
                    ) {
                      item.push(crow.id);
                    }
                  });
                }

                newArray.push({
                  pm_id: row.pm_id,
                  iteam: item,
                });
              } else {
                newArray.push({
                  pm_id: row.pm_id,
                  iteam: this.pmPayloadVisit[index].iteam,
                  scheduleId: this.pmPayloadVisit[index].scheduleId,
                });
              }
            });
          }
          this.pmPayloadVisit = newArray;
        }
        const request = {
          ...this.jobCreate,
          default_started_at: this.defaultStartedAt
            ? this.defaultStartedAt
            : moment().format("YYYY-MM-DD HH:mm"),
          job_type: this.jobCreate.job_type ? [this.jobCreate.job_type] : [],
          default_finished_at: this.defaultFinishedAt
            ? this.defaultFinishedAt
            : moment().format("YYYY-MM-DD HH:mm"),
          update_schedule_changed: +this.updateScheduleChanged,
          update_team_changed: +this.updateTeamChanged,
          update_target_type: this.updateTargetType,
          /*  attachments: this.jobCreate && this.jobCreate.attachments ? [...this.jobCreate.attachments, ...new_attachments]
                : [], */
          ticket: this.ticketId,
          visit: this.visitId,
          /* priventive:
              this.dbPriventiveId && this.dbPriventiveId > 0
                ? this.dbPriventiveId
                : 0, */
          enquiry:
            this.dbEnquiryId && this.dbEnquiryId > 0 ? this.dbEnquiryId : 0,
          quotation:
            this.dbQuotationId && this.dbQuotationId > 0
              ? this.dbQuotationId
              : 0,
          invoice:
            this.dbInvoiceId && this.dbInvoiceId > 0 ? this.dbInvoiceId : 0,
          schedule_id:
            this.dbschedule_id && this.dbschedule_id > 0
              ? this.dbschedule_id
              : 0,
          customer: this.vCustomerId,
          property: this.vPropertyId,
          is_pm: this.pmPayloadVisit && this.pmPayloadVisit.length > 0 ? 1 : 0,
          pm_array:
            this.pmPayloadVisit && this.pmPayloadVisit.length
              ? this.pmPayloadVisit
              : [],
          property_contact: this.vPropertyContactId,
          billing: this.vBillingId,
          billing_contact: this.vBillingContactId,
          calculation: this.vCalculations,
          "line-item": this.vSelectedLineItem,
          team: this.assignedTeam,
          team_object: this.assignedTeamObject,
          clear_engineers: +!this.assignedTeam.length,
          /*   attachments:this.attachments,
            vPdfAttachments:this.vPdfAttachments, */
          oneoff:
            this.jobCreate.schedule_tab == "one-off" ? this.oneOffSchedule : {},
          recurring:
            this.jobCreate.schedule_tab == "recurring"
              ? this.recurringSchedule
              : {},
          schedule:
            this.jobCreate.schedule_tab == "recurring"
              ? this.scheduleOutput
              : [],
          oneoffinvoice:
            this.jobCreate.schedule_tab == "one-off" ? this.oneoffinvoice : {},
          recurringinvoice:
            this.jobCreate.schedule_tab == "recurring"
              ? this.recurringinvoice
              : {},
          invoiceSchedule:
            this.jobCreate.schedule_tab == "recurring"
              ? this.invoiceVisitScheduleOutput
              : [],
        };
        /*  this.$store.commit(SET_LOADING, true); */
        this.loaderLoding = true;
        this.$store.dispatch(CREATE_OR_UPDATE, request).then(() => {
          this.loaderLoding = false;
          this.jobCreated = true;
          if (this.jobCreate.schedule_tab == "recurring") {
            this.confirmDialog = false;
            this.$router.replace({
              name: "admin.recurring-job",
              query: { status: "all" },
            });
          } else {
            this.$router.replace({
              name: "admin.visit",
              query: { status: "all" },
            });
          }
        });
      } catch (error) {
        this.loaderLoding = false;
        console.log(error);
      } /*  finally {
        this.$store.commit(SET_LOADING, false);
      } */
    },
    updateScheduleType(param) {
      this.scheduleKey = Number(new Date());
      this.$store.commit(SET_SCHEDULE_TYPE, param);
    },
    getTicket() {
      this.$store
        .dispatch(GET_TICKET, {
          ticket: this.ticketId,
          visit: this.visitId,
          /*   search: "",
          related_text: "",
          type: "", */
        })
        .then(() => {
          this.isEditLoaded = true;

          this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Visit", route: "visit" },
            { title: "Update" },
            { title: this.jobEditData?.visit?.barcode },
          ]);
          this.updatetedTicket = this.jobEditData?.ticket;
          let defaultTermConditionObject = this.lodash.find(
            this.jobOption.term_condition_list,
            {
              primary: 1,
            }
          );

          if (!this.lodash.isEmpty(defaultTermConditionObject)) {
            this.jobCreate.term_condition_id = defaultTermConditionObject.id;
            this.jobCreate.term_conditions =
              defaultTermConditionObject.description;
          }
          this.jobCreate = {
            schedule_tab:
              this.jobEditData?.ticket?.type == 2 ? "recurring" : "one-off",
            priority: this.jobEditData?.ticket?.priority,
            title: this.jobEditData?.ticket?.title,
            description: this.jobEditData?.ticket?.description,
            remark: this.jobEditData?.ticket?.additional_remarks,
            barcode: this.jobEditData?.ticket?.barcode,
            option: this.jobEditData?.ticket?.option,
            reference: this.jobEditData?.ticket?.reference,
            job_type: this.jobEditData?.ticket?.ticket_type
              ? this.jobEditData?.ticket?.ticket_type.join("")
              : null,
            term_condition_id: this.jobEditData?.ticket?.term_condition_id,
            /*  collected_amount: this.jobEditData?.ticket?.amount_to_be_collected, */
            term_conditions: this.jobEditData?.ticket?.term_conditions,
            client_remark: this.jobEditData?.ticket?.client_remark,
            admin_remark: this.jobEditData?.ticket?.admin_remark,
            email_notify: this.jobEditData?.ticket?.notify_engineer,
            po_number: this.jobEditData?.ticket?.po_number,
            payment_mode: this.jobEditData?.ticket?.payment_mode,
            is_remark_show: this.jobEditData?.visit?.is_remark_show,
            tags: this.jobEditData?.ticket?.tags,
            quotation: this.jobEditData?.visit?.quotation,
            is_service_form: this.jobEditData?.visit?.is_service_form,
            is_item_pdf: this.jobEditData?.visit?.is_item_pdf,
            is_payment_needed: this.jobEditData?.ticket?.is_payment_needed,
            attachments: [],
            /*    invoice_needed: this.jobEditData?.visit?.is_item_pdf,
            invoice_main_type: this.jobEditData?.visit?.is_item_pdf,
            invoice_type: this.jobEditData?.visit?.is_item_pdf,
            payment_due: this.jobEditData?.visit?.is_item_pdf,
            send_invoice: this.jobEditData?.visit?.is_item_pdf, */
          };

          if (this.jobEditData?.ticket?.btx_schedule_type == "one-off") {
            this.$store.commit(UPDATE_INVOICE_SCHEDULE_ONE_OFF, {
              key: "oneoffinvoice.invoice_needed",
              value: this.jobEditData?.visit?.invoice_needed,
            });
            this.$store.commit(UPDATE_INVOICE_SCHEDULE_ONE_OFF, {
              key: "oneoffinvoice.invoice_type",
              value: this.jobEditData?.visit?.invoice_type,
            });
            this.$store.commit(UPDATE_INVOICE_SCHEDULE_ONE_OFF, {
              key: "oneoffinvoice.payment_due",
              value: this.jobEditData?.visit?.payment_due,
            });
            this.$store.commit(UPDATE_INVOICE_SCHEDULE_ONE_OFF, {
              key: "oneoffinvoice.send_invoice",
              value: this.jobEditData?.visit?.send_invoice,
            });
          } else {
            this.$store.commit(UPDATE_INVOICE_SCHEDULE_RECURRING, {
              key: "recurringinvoice.invoice_needed",
              value: this.jobEditData?.visit?.invoice_needed,
            });
            this.$store.commit(UPDATE_INVOICE_SCHEDULE_RECURRING, {
              key: "recurringinvoice.invoice_main_type",
              value: this.jobEditData?.visit?.invoice_main_type,
            });
            this.$store.commit(UPDATE_INVOICE_SCHEDULE_RECURRING, {
              key: "recurringinvoice.invoice_type",
              value: this.jobEditData?.visit?.invoice_type,
            });
            this.$store.commit(UPDATE_INVOICE_SCHEDULE_RECURRING, {
              key: "recurringinvoice.payment_due",
              value: this.jobEditData?.visit?.payment_due,
            });
            this.$store.commit(UPDATE_INVOICE_SCHEDULE_RECURRING, {
              key: "recurringinvoice.send_invoice",
              value: this.jobEditData?.visit?.send_invoice,
            });
          }
        })
        .catch(() => {
          /* this.goBack(); */
        })
        .finally(() => {
          //
        });
    },
    getCustomer(customerId, contactPersonId, propertyId) {
      this.$store
        .dispatch(QUERY, {
          url: `customer-with-relation/${customerId}`,
          data: {
            contact_person: contactPersonId,
            property: propertyId,
          },
        })
        .then((response) => {
          this.$store.commit(
            SET_CUSTOMER,
            ObjectPath.get(response, "data.customer", {})
          );
          this.$store.commit(
            SET_PROPERTY,
            ObjectPath.get(response, "data.property", {})
          );
          this.$store.commit(
            SET_PROPERTY_CONTACT,
            ObjectPath.get(response, "data.property_contact_person", {})
          );
          this.$store.commit(
            SET_BILLING,
            ObjectPath.get(response, "data.billing", {})
          );
          this.$store.commit(
            SET_BILLING_CONTACT,
            ObjectPath.get(response, "data.billing_contact_person", {})
          );
        })
        .catch(() => {
          this.goBack();
        })
        .finally(() => {
          //
        });
    },
    getDuplicateTicket() {
      this.$store
        .dispatch(GET_TICKET, {
          ticket: this.duplicateTicketId,
          visit: this.duplicateVisitId,
        })
        .then(() => {
          this.jobCreate.schedule_tab =
            this.jobEditData?.ticket?.type == 2 ? "recurring" : "one-off";
          this.jobCreate.priority = this.jobEditData?.ticket?.priority;
          this.jobCreate.title = this.jobEditData?.ticket?.title;
          this.jobCreate.description = this.jobEditData?.ticket?.description;
          this.jobCreate.remark = this.jobEditData?.ticket?.additional_remarks;
          this.jobCreate.collected_amount =
            this.jobEditData?.ticket?.amount_to_be_collected;
          this.jobCreate.term_conditions =
            this.jobEditData?.ticket?.term_conditions;
          this.jobCreate.client_remark =
            this.jobEditData?.ticket?.client_remark;
          this.jobCreate.admin_remark = this.jobEditData?.ticket?.admin_remark;
          this.jobCreate.email_notify =
            this.jobEditData?.ticket?.notify_engineer;
          this.jobCreate.attachments = [];
        })
        .catch(() => {
          this.goBack();
        })
        .finally(() => {
          //
        });
    },
    getVisitOption() {
      this.$store
        .dispatch(GET_OPTION, {
          visit: this.lodash.toSafeInteger(this.updateVisitId),
          job: this.lodash.toSafeInteger(this.updateTicketId),
          priventive: this.lodash.toSafeInteger(this.dbPriventiveId),
          quotation: this.lodash.toSafeInteger(this.dbQuotationId),
          invoice: this.lodash.toSafeInteger(this.dbInvoiceId),
          enquiry: this.lodash.toSafeInteger(this.dbEnquiryId),
          dbschedule_id: this.lodash.toSafeInteger(this.dbschedule_id),
          do_update: this.lodash.toSafeInteger(this.do_update),
          customer: this.lodash.toSafeInteger(this.vCustomerId),
        })
        .then(() => {
          this.is_visit_invoice_allow = this.jobOption.is_visit_invoice_allow;
          this.is_visit_recur_invoice_allow =
            this.jobOption.is_visit_recur_invoice_allow;
          if (
            this.visitId == 0 &&
            this.jobOption &&
            this.jobOption.service_form
          ) {
            if (this.jobOption.service_form.allow_service_form == 1) {
              this.jobCreate.is_service_form = true;
            } else {
              this.jobCreate.is_service_form = false;
            }
          }
          if (this.jobOption?.priventive?.ticket) {
            this.isEditPage = true;
            this.isPreventivePage = true;
            this.updatetedTicket = this.jobOption?.priventive?.ticket;
            const priventiveTicket = this.jobOption?.priventive?.ticket;
            this.jobCreate = {
              schedule_tab:
                priventiveTicket?.type == 2 ? "recurring" : "one-off",
              priority: priventiveTicket?.priority,
              title: priventiveTicket?.title,
              description: priventiveTicket?.description,
              remark: priventiveTicket?.additional_remarks,
              barcode: priventiveTicket?.barcode,
              option: priventiveTicket?.option,
              reference: priventiveTicket?.reference,
              job_type: priventiveTicket?.ticket_type,
              term_conditions: priventiveTicket?.term_conditions,
              client_remark: priventiveTicket?.client_remark,
              admin_remark: priventiveTicket?.admin_remark,
              email_notify: priventiveTicket?.notify_engineer,
              po_number: priventiveTicket?.po_number,
              payment_mode: priventiveTicket?.payment_mode,
              is_remark_show: priventiveTicket?.is_remark_show,
              tags: priventiveTicket?.tags,
            };
            if (priventiveTicket?.type == 2) {
              const schedule =
                this.jobOption?.daily ??
                this.jobOption?.weekly ??
                this.jobOption?.monthly;

              const startedAt = new Date(
                `${schedule?.start_date} ${schedule?.start_time}`
              );
              const finishedAt = new Date(
                `${schedule?.end_date} ${schedule?.end_time}`
              );

              this.$store.commit(UPDATE_DEFAULT_DATE, {
                startedAt,
                finishedAt,
              });

              const type = this.recurringScheduleTypeList.find(
                (row) => row.value == priventiveTicket?.btx_schedule_tab
              );

              this.$store.commit(UPDATE_SCHEDULE_STATE, {
                key: "dbRecurringSchedule.type",
                value: type,
              });

              this.$store.commit(UPDATE_SCHEDULE_STATE, {
                key: "dbRecurringSchedule.message",
                value: priventiveTicket.schedule_message,
              });

              this.$store.commit(UPDATE_SCHEDULE_STATE, {
                key: "dbRecurringSchedule.weeks",
                value: schedule?.weeks ?? [],
              });

              this.$store.commit(UPDATE_SCHEDULE_STATE, {
                key: "dbRecurringSchedule.occurrence",
                value: toSafeInteger(schedule?.occurrence) ?? 1,
              });

              this.$store.commit(UPDATE_SCHEDULE_STATE, {
                key: "dbRecurringSchedule.end_mode",
                value: toSafeInteger(schedule?.end_mode) ?? 1,
              });

              this.$store.commit(UPDATE_SCHEDULE_STATE, {
                key: "dbRecurringSchedule.start_date",
                value: moment(startedAt).format("YYYY-MM-DD"),
              });

              this.$store.commit(UPDATE_SCHEDULE_STATE, {
                key: "dbRecurringSchedule.end_date",
                value: moment(finishedAt).format("YYYY-MM-DD"),
              });

              this.$store.commit(UPDATE_SCHEDULE_STATE, {
                key: "dbRecurringSchedule.start_time",
                value: moment(startedAt).format("hh:mm A"),
              });

              this.$store.commit(UPDATE_SCHEDULE_STATE, {
                key: "dbRecurringSchedule.end_time",
                value: moment(finishedAt).format("hh:mm A"),
              });

              this.$store.commit(UPDATE_SCHEDULE_STATE, {
                key: "dbRecurringSchedule.week_day",
                value: toSafeInteger(schedule?.week_day) ?? 1,
              });

              this.$store.commit(UPDATE_SCHEDULE_STATE, {
                key: "dbRecurringSchedule.week_count",
                value: toSafeInteger(schedule?.week_count) ?? 1,
              });

              this.$store.commit(UPDATE_SCHEDULE_STATE, {
                key: "dbRecurringSchedule.second_week_day",
                value: toSafeInteger(schedule?.second_week_day) ?? 1,
              });

              this.$store.commit(UPDATE_SCHEDULE_STATE, {
                key: "dbRecurringSchedule.second_week_count",
                value: toSafeInteger(schedule?.second_week_count) ?? 1,
              });

              this.$store.commit(UPDATE_SCHEDULE_STATE, {
                key: "dbRecurringSchedule.recurring_pattern",
                value: schedule.recurring_pattern
                  ? toSafeInteger(schedule.recurring_pattern)
                  : 1,
              });

              this.$store.dispatch(GET_RECURRING_DATE);
            }
            this.$store.commit(SET_CUSTOMER, priventiveTicket?.customer);
            this.$store.commit(SET_PROPERTY, priventiveTicket?.property);
            this.$store.commit(
              SET_PROPERTY_CONTACT,
              priventiveTicket?.property_person
            );
            this.$store.commit(SET_BILLING, priventiveTicket?.billing);
            this.$store.commit(
              SET_BILLING_CONTACT,
              priventiveTicket?.billing_person
            );
          } else if (this.jobOption?.enquiry?.id && this.dbEnquiryId > 0) {
            this.updatetedTicket = this.jobOption?.enquiry;
            this.related_type = 6;
            const enquiryData = this.jobOption?.enquiry;
            this.jobCreate = {
              schedule_tab: "one-off",
              priority: enquiryData?.priority,
              title: enquiryData?.title,
              description: enquiryData?.description,
              remark: enquiryData?.additional_remarks,
              barcode: enquiryData?.barcode,
              option: "internal",
              reference: enquiryData?.reference,
              client_remark: enquiryData?.technician_sketch,
              admin_remark: enquiryData?.admin_remark,
              email_notify: enquiryData?.notify_property_contact_person,
              is_remark_show: true,
              is_service_form: true,
              is_item_pdf: true,
              is_payment_needed: true,
              tags: enquiryData?.tags,
            };
            this.$store.commit(SET_CUSTOMER, enquiryData?.customer_relation);
            this.$store.commit(SET_PROPERTY, enquiryData?.property_relation);
            this.$store.commit(
              SET_PROPERTY_CONTACT,
              enquiryData?.property_contact_person_relation
            );
            this.$store.commit(SET_BILLING, enquiryData?.billing_relation);
            this.$store.commit(
              SET_BILLING_CONTACT,
              enquiryData?.billing_contact_person_relation
            );
          } else if (this.jobOption?.quotation?.id && this.dbQuotationId > 0) {
            this.updatetedTicket = this.jobOption?.quotation;
            this.related_type = 1;
            const quotationData = this.jobOption?.quotation;
            this.jobCreate = {
              schedule_tab: this.$route?.query?.type ?? "one-off",
              priority: "3",
              title: quotationData?.title,
              remark: quotationData?.additional_remarks,
              barcode: quotationData?.barcode,
              option: "internal",
              reference: quotationData?.reference,
              client_remark: quotationData?.client_note,
              admin_remark: quotationData?.admin_note,
              term_conditions: quotationData?.term_conditions,
              term_condition_id: toSafeInteger(
                quotationData?.term_condition_id
              ),
              email_notify: quotationData?.notify_property_contact_person,
              is_remark_show: true,
              is_service_form: true,
              is_item_pdf: true,
              is_payment_needed: true,
              tags: quotationData?.tags,
            };
            this.$store.commit(SET_CUSTOMER, quotationData?.customer_relation);
            this.$store.commit(SET_PROPERTY, quotationData?.property_relation);
            this.$store.commit(
              SET_PROPERTY_CONTACT,
              quotationData?.property_contact_person_relation
            );
            this.$store.commit(SET_BILLING, quotationData?.billing_relation);
            this.$store.commit(
              SET_BILLING_CONTACT,
              quotationData?.billing_contact_person_relation
            );
          } else if (this.jobOption?.invoice?.id && this.dbInvoiceId > 0) {
            this.updatetedTicket = this.jobOption?.invoice;
            this.related_type = 2;
            const invoiceData = this.jobOption?.invoice;
            this.jobCreate = {
              schedule_tab: "one-off",
              priority: "3",
              title: invoiceData?.title,
              description: invoiceData?.description,
              remark: invoiceData?.additional_remarks,
              barcode: invoiceData?.barcode,
              option: "internal",
              reference: invoiceData?.customer_ref,
              email_notify: invoiceData?.notify_property_contact_person,
              is_remark_show: true,
              is_service_form: true,
              is_item_pdf: true,
              is_payment_needed: true,
              tags: invoiceData?.tags,
            };
            this.$store.commit(SET_CUSTOMER, invoiceData?.customer_relation);
            this.$store.commit(SET_PROPERTY, invoiceData?.property_relation);
            this.$store.commit(
              SET_PROPERTY_CONTACT,
              invoiceData?.property_contact_person_relation
            );
            this.$store.commit(SET_BILLING, invoiceData?.billing_relation);
            this.$store.commit(
              SET_BILLING_CONTACT,
              invoiceData?.billing_contact_person_relation
            );
          }
        })
        .catch((error) => {
          console.log({ error });
        })
        .finally(() => {
          //
        });
    },
    getPMVisitSchedules() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(QUERY, {
          url: "preventive-visit-schedule",
          data: {
            payload: this.pmVisitScheduleData,
          },
        })
        .then(({ data }) => {
          this.allConsumePMData = data;
          this.$store.commit(SET_CUSTOMER, data?.preventives[0]?.customer);
          this.$store.commit(SET_PROPERTY, data?.preventives[0]?.property);
          this.$store.commit(
            SET_PROPERTY_CONTACT,
            data?.preventives[0]?.property_person
          );
          this.$store.commit(SET_BILLING, data?.preventives[0]?.billing);
          this.$store.commit(
            SET_BILLING_CONTACT,
            data?.preventives[0]?.billing_person
          );
          this.$store.dispatch(SET_VISIT_LINE_ITEM, data?.pmItems);
          if (this.vSelectedLineItem) {
            this.setIteam = true;
          }
          /*   console.log(this.$store.dispatch(SET_VISIT_LINE_ITEM, data?.pmItems)) */
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },
  created() {
    this.ticketId = Number(this.$route?.query?.ticket ?? 0);
    this.visitId = Number(this.$route?.query?.visit ?? 0);
    this.pmVisitScheduleData = this.$route?.query?.pmSchedule;
    if (this.pmVisitScheduleData) {
      this.pmPayloadVisit = JSON.parse(this.pmVisitScheduleData);
    }

    this.duplicateTicketId = Number(
      this.$route?.query["duplicate-ticket"] ?? 0
    );
    this.duplicateVisitId = Number(this.$route?.query["duplicate-visit"] ?? 0);
    /* this.dbPriventiveId = this.lodash.toSafeInteger(
        this.$route.query?.priventive ?? 0
      ); */
    this.dbEnquiryId = this.lodash.toSafeInteger(
      this.$route.query?.enquiry ?? 0
    );
    this.dbInvoiceId = this.lodash.toSafeInteger(
      this.$route.query?.invoice ?? 0
    );
    this.dbQuotationId = this.lodash.toSafeInteger(
      this.$route.query?.quotation ?? 0
    );
    this.dbschedule_id = this.lodash.toSafeInteger(
      this.$route.query?.schedule_id ?? 0
    );
  },
  destroyed() {
    this.$store.dispatch(CLEAR_TICKET);
    this.$store.dispatch(CLEAR_INVOICE);
    this.$store.dispatch(RESET_STATE);
    this.$store.dispatch(RESET_CREATE_STATE);
    this.$store.dispatch(CLEAR_CUSTOMER);
  },
  mounted() {
    this.getVisitOption();

    if (this.duplicateTicketId && this.duplicateVisitId) {
      this.getDuplicateTicket();
    }
    if (this.pmVisitScheduleData?.length > 0) {
      this.getPMVisitSchedules();
    }
    this.jobCreate.schedule_tab = this.$route?.query?.type ?? "one-off";
    const customerId = Number(this.$route?.query?.customer ?? 0);
    const contactPersonId = Number(this.$route?.query?.contact_person ?? 0);
    const propertyId = Number(this.$route?.query?.property ?? 0);

    if (customerId) {
      this.getCustomer(customerId, contactPersonId, propertyId);
    }
    /*   if(this.dbPriventiveId && this.ticketId){
          this.getTicket();
        } */
    if (this.ticketId && this.visitId) {
      this.getTicket();
      this.isEditPage = true;
      this.$store.commit(SET_EDIT_MODE, true);
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Visit", route: "visit" },
        { title: "Update" },
      ]);
      this.showCollectedAmount(this.jobCreate.is_payment_needed);
    } else {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Visit", route: "visit" },
        { title: "Create" },
      ]);
      this.showCollectedAmount(true);
    }

    this.$refs.createTicketForm.validate();
  },
  beforeRouteLeave(to, from, next) {
    const _this = this;
    if (_this.jobCreated) {
      next();
    } else {
      _this.routePreventDialog = true;
      PreventDialogEventBus.$on("stay-on-page", function () {
        _this.routePreventDialog = false;
        next(false);
      });
      PreventDialogEventBus.$on("leave-page", function () {
        _this.routePreventDialog = false;
        next();
      });
    }
  },
};
</script>
<style lang="scss" scoped>
.quotation-create-page {
  border-top: solid 8px #f7941e;

  label {
    padding-left: 5px;
    font-weight: 600;
  }

  p {
    padding-left: 5px;
  }
}

.form-action {
  display: flex;
  justify-content: space-between;

  .form-title {
    font-size: 24px;
    font-family: "Poppins", Helvetica, Arial, sans-serif;
    font-weight: 700;
    color: #24326d;
    letter-spacing: 1.5px;
    line-height: 1.11em;

    .form-title-link {
      color: #4d6974;
      border-bottom: dashed 2px #4d6974;
    }
  }
}
</style>
